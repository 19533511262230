<template>
  <div class="prepaid">
    <div class="prepaid__content" v-loading="loading">
      <div class="prepaid__title">
        {{ editData ? $t('vk_ads.user') : $t('vk_ads.add_user')}}
      </div>
      <div class="prepaid__steps">
        <div class="prepaid__step">
          <div class="prepaid__step-title">
            1. {{ $t('vk_ads.vk_ads_use_id') }}
          </div>
          <div v-if="!editData" class="prepaid__step-content">
            <div
              class="form-input"
              :class="[{'form-input--full' : form.vkLink},{'form-input--error' : errors.vkLink}]"
            >
              <input v-model="form.vkLink" type="text" required>
              <span class="form-input__label">ID</span>
              <Tooltip :data="$t('tooltip_text.VkReclamaDOP.0')"/>              <div
                v-if="errors.vkLink"
                class="form-input__error"
                v-text="errors.vkLink"
              />
            </div>
          </div>
          <div v-else class="prepaid__step-content">
            <div class="vk-user-info">
              <div class="vk-user-info-top" :class="{'locale-usa':locale==='USA'}">
                <!-- <a :href="`https://vk.com/id${editData.user_id}`" target="_blank" class="vk-user-info__link">https://vk.com/id{{ editData.user_id }}</a> -->
                <el-popconfirm 
                  :title="$t('vk_ads.del_confirm')"
                  :confirm-button-text="$t('vk_ads.del')"
                  :cancel-button-text="$t('vk_ads.cancel')"
                  :hideIcon="true"
                  @confirm="dellUser"
                >
                  <template #reference>
                    <div class="vk-user-info-dell">
                      <img src="@/assets/icons/icon-delete-3.svg" alt="">
                      <span>{{ $t('vk_ads.delete_user') }}</span>
                    </div>
                  </template>
                </el-popconfirm>
              </div>
              <p class="vk-user-info__name">{{ editData.first_name }} {{ editData.last_name }}</p>
            </div>
          </div>
        </div>

        <div v-if="accounts && accounts.length" class="prepaid__step">
          <div class="prepaid__step-title">
            2. {{ $t('vk_ads.list_of_accounts') }}
          </div>
          <div class="prepaid__step-content">
            <div class="selector">
              <div class="selector__content" @click="showOptions = !showOptions">
                <div class="selector__title">
                  {{ $t('vk_ads.add_accounts') }}
                </div>
                <div class="selector__arrow" :class="{'selector__arrow--open' : showOptions}">
                  <img src="@/assets/icons/icon-arrow.svg" alt="arrow">
                </div>
              </div>
              <div v-if="showOptions" class="selector__options vk-selector">
                <div
                    class="form-input selector__options-search"
                    :class="
                  [
                    {'form-input--full' : search}
                  ]"
                >
                  <input v-model="search" type="text" required>
                  <span class="form-input__label">
                    {{ $t('search_props.placeholder') }}
                  </span>
                </div>
                <div
                    v-for="account in filtredAccounts"
                    :key="account.id"
                    class="selector__options-item"
                    :class="{'selector__options-item--active': checkStatus(account.id)}"
                    @click="chooseAccount(account.account_name, account.description, account.id)"
                >
                  <div v-html="account.description" />
                  <div v-html="account.account_name" />
                </div>
              </div>
            </div>
            <div class="selected-accounts">
              <div v-for="item in selectedAccounts" :key="item.id" class="selected-accounts__item selected-accounts__item--admin">
                <div class="selected-accounts__item-name" v-html="item.name" />
                <div class="selected-accounts__item-email" v-html="item.account_name" />
                <div class="selected-accounts__item-dell" @click="dellAcc(item.id)">
                  <img src="@/assets/icons/icon-vk-acc-dell.svg">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="prepaid__final">
      <ButtonElement
        :text="editData ? $t('button_props.save_changes') : $t('button_props.add_user')"
        :disabled="!form.vkLink || (selectedAccounts && !selectedAccounts.length)"
        @click-button="addAccount"
      />
    </div>
  </div>
</template>

<script>
import ButtonElement from "@/components/elements/ButtonElement";
import formValidate from "@/mixin/formValidate";
import numberWithSpaces from '@/mixin/numberWithSpaces';
import Tooltip from "../elements/Tooltip.vue";

export default {
  name: 'AddVkAdds',
  components: {
    ButtonElement,
    Tooltip
  },
  mixins: [formValidate, numberWithSpaces],
  data() {
    return {
      locale: process.env.VUE_APP_LOCALE,
      showOptions: false,
      selectedAccounts: null,
      deletedAccounts: null,
      form: {
        vkLink: '',
      },
      errors: {
        vkLink: '',
      },
      loading: false,
      search: ''
    }
  },
  computed: {
    activeAccount() {
      return this.$store.state.Auth.activeAccount
    },
    accounts() {
      return this.$store.state.Ad.accountsByBusinessAccount
    },
    editData() {
      return this.$store.state.Ad.vkObserverEdit
    },
    filtredAccounts() {
      if (this.search && this.accounts) {
        return this.accounts.filter( el => 
          (el.description && el.description.toLowerCase().includes(this.search.toLowerCase())) ||
          (el.account_name && el.account_name.toLowerCase().includes(this.search.toLowerCase()))
        )
      } else if (this.accounts) {
        return this.accounts
      }
      return []
    }
  },
  created() {
    this.$store.dispatch('Ad/getAccountsByBusinessAccount', {
      service : 'vkads',
      business_account_id: this.activeAccount.id
    })

    if (this.editData && this.editData.clients) {
      this.form.vkLink = `https://vk.com/id${this.editData.user_id}`
      this.selectedAccounts = JSON.parse(JSON.stringify(this.editData.clients))
      this.selectedAccounts.forEach(el => {
        el.id = el.account_id
      })
    }
  },
  methods: {
    addAccount() {
      const accounts = []
      if (this.selectedAccounts) {
        this.selectedAccounts.forEach(el => {
          accounts.push(el.id)
        })
      }
      if (!this.editData) {
        this.$store.dispatch('Ad/addObserver', {
          service : 'vkads',
          user_name: this.form.vkLink,
          business_account_id: this.activeAccount.id,
          accounts
        })
          .then(() => {
            this.getObservers()
            this.cleanData()
            this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: null })
          })
      } else {
        if (this.deletedAccounts && this.deletedAccounts.length) {
          this.$store.dispatch('Ad/observerDetach', {
            service : 'vkads',
            observer_id: this.editData.id,
            account_id: this.deletedAccounts,
            business_account_id: this.activeAccount.id
          })
        }
        if (accounts.length) {
          this.$store.dispatch('Ad/observerAttach', {
            service : 'vkads',
            observer_id: this.editData.id,
            account_id: accounts,
            business_account_id: this.activeAccount.id
          })
        }  
        this.getObservers()
        this.cleanData()
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
      } 
    },
    chooseAccount(name, account_name, id) {
      if (this.deletedAccounts) {
        const index = this.deletedAccounts.findIndex(el => el === id)
        if (index !== -1) {
          this.deletedAccounts.splice(index, 1)
        }
      }
      if (!this.selectedAccounts) { 
        this.selectedAccounts = [{ name, account_name, id }] 
      } else {
        const index = this.selectedAccounts.findIndex(el => el.id === id)
        if (index === -1) {
          this.selectedAccounts.push(
            { name, account_name, id }
          )
        } else {
          this.dellAcc(id)
        }
      }
    },
    checkStatus(id) {
      if(this.selectedAccounts) {
        const checked = this.selectedAccounts.findIndex(el => el.id === id)
        return checked === -1 ? false : true
      }
    },
    dellAcc(id) {
      if (!this.deletedAccounts) { 
        this.deletedAccounts = [id] 
      } else {
        this.deletedAccounts.push(id) 
      }
      const index = this.selectedAccounts.findIndex(el => el.id === id)
      if (index !== -1) {
        this.selectedAccounts.splice(index, 1)
      }
    },
    dellUser() {
      this.$store.dispatch('Ad/dellUser', {
        service : 'vkads',
        observer_id: this.editData.id,
        business_account_id: this.activeAccount.id
      })
      .then(() => {
        this.getObservers()
        this.$store.commit('Dialog/setData', { label: 'dialogVisible', data: false })
      })
    },
    getObservers() {
      this.$store.dispatch('Ad/getObserver', {
        service : 'vkads',
        business_account_id: this.activeAccount.id
      })
    },
    cleanData() {
      this.selectedAccounts = null,
      this.deletedAccounts = null,
      this.form.vkLink = ''
      this.$store.commit('Ad/setData', { label: 'vkObserverEdit', data: null })
    }
  }
}
</script>

<style scoped lang="scss">
@import "@/assets/styles/helpers/variables";
@import "@/assets/styles/helpers/mixin";

.prepaid {
  background: #fff;
  color: #000;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.7rem;
    margin-bottom: 6.5rem;
    color: #000;
  }

  &__content {
    padding: 7rem 6rem;
  }

  &__final {
    width: 100%;
    background: #EFF1F4;
    padding: 3.3rem 6rem;
    display: flex;

    .button {
      width: 27rem;
      height: 4.5rem;
    }
  }

  &__step {
    margin-bottom: 6rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &-title {
      font-weight: 700;
      font-size: 2.2rem;
      line-height: 2.4rem;
      color: #333333;
      margin-bottom: 2rem;
    }

    &-content {
      .form-input {
        width: 100%;
        max-width: 35rem;
        position: relative;

        .tooltip-wrapper {
          position: absolute;
          left: 100%;
          top: 25%;
        }

        @include below($sm) {
          max-width: 100%;
        }
      }
    }

    &-error {
      color: $redLight;
      position: absolute;
      left: 6rem;
    }
  }

  .selector {
    position: relative;
    width: 100%;
    max-width: 35rem;

    @include below($sm) {
      max-width: 100%;
    }

    &__content {
      background: $backgroundInput;
      border: 1px solid $border;
      border-radius: 2px;
      height: 5rem;
      position: relative;
      padding: 0.7rem 1.5rem;
      width: 100%;
      display: flex;
      align-items: center;
    }

    &__title {
      font-size: 1.6rem;
      line-height: 1.4rem;
      color: $basicBlack;
      margin-bottom: 0.2rem;
    }

    &__value {
      font-size: 1.6rem;
      line-height: 1.9rem;
    }

    &__arrow {
      cursor: pointer;
      position: absolute;
      right: 1.5rem;
      top: 1.5rem;

      img {
        transition: 0.3s ease-out;
      }

      &--open {
        img {
          transform: rotate(180deg);
        }
      }
    }

    &__options {
      position: absolute;
      top: calc(100% + 1rem);
      left: 0;
      width: 100%;
      background: $backgroundInput;
      border: 1px solid $border;
      border-radius: 2px;
      z-index: 1;
      overflow: auto;
      max-height: 30rem;

      &-item {
        cursor: pointer;
        transition: 0.3s ease-out;
        padding: 0.7rem 1.5rem;
        margin: .3rem 0;

        &:hover {
          background: var(--primary);
          color: $basicWhite;
        }

        &--active {
          background: var(--primary);
          color: $basicWhite;
        }
      }

      &-search {
        width: calc(100% - 20px);
        margin: 10px;
      }
    }

    @include below(769px) {
      &__content {
        width: 100%;
      }
    }
  }
}

.vk-user-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  &-top {
    order: 2;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
 
  }

  &-dell {
    color: $redLight;
    display: flex;
    align-items: center;
    cursor: pointer;

    img {
      margin-right: .8rem;
    }
  }

  &__link {
    margin: .8rem 1rem .8rem 0;
    color: $primary;
    font-size: 1.6rem;
    font-weight: 700;
  }

  &__name {
   font-size: 1.6rem;
   margin: 0.8rem 1rem 0.8rem 0; 
  }
}

@include below(993px) {
  .prepaid__title{
    font-size: 22px;
    line-height: 1.3;
    margin-bottom: 30px;
  }

  .prepaid__step{
    margin-bottom: 30px;
  }

  .prepaid__final,
  .prepaid__content{
    padding: 15px;
  }
}

@include below(769px) {
  .prepaid__final{
    .button{
      width: 100%;
      margin-top: 10px;
    }
  }
}

@include below($sm) {
  .vk-selector{
    position: relative !important;
  }
}
</style>
